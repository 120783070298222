<template>

  <div class="footer bottom medium">
    <b-row>
        <b-col cols="2">  </b-col>

    <p style="margin-top:2px; font-size: 12px; "> Nivas Durairaj &copy; {{ date }} // Site created with <a target="_blank" href="https://vuejs.org/">Vue.js</a>, <a target="_blank" href="https://getbootstrap.com/">Bootstrap</a> and <a target="_blank" href="https://docs.amplify.aws/">AWS Amplify</a></p> 
    </b-row>
  </div>

</template>

<script>
export default {
  name: 'myfooter',
  data: function () {
    return { date: 
    new Date().toLocaleString( 'sv', { timeZoneName: 'short' } ) }
     //new Date().toISOString().substr(0, 10)  }
  },
  mounted() {
    this.interval = setInterval(() => {
      this.updateDiffs();
    },1000);
    
    this.updateDiffs();
  },
  methods:{
    updateDiffs() {
      this.date = new Date().toLocaleString( 'sv', { timeZoneName: 'short' } ) 
      }
    
  }


  

}

</script>

<style scoped>
a, a:hover, a:visited {
  color: rgb(238, 243, 248);
}
</style>