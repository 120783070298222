<template>
  <div id="app">
    <HelloWorld msg="Nivas Durairaj"/>
    <myfooter/>
</div>
</template>

<script>
import HelloWorld from '../components/HelloWorld.vue'
import myfooter from '../components/myfooter.vue'


export default {
  name: 'App',
  components: {
    HelloWorld,
    myfooter
  },
  data: function () {
    return { date: 
    
     new Date().toISOString().substr(0, 10)  }
      // 05/09/2019
  }

}
</script>

<style scoped>
.app {
  background-color: black; 
}
</style>

